import Cropper from 'cropperjs';

export default class ImageCropper {
  static init() {
    queryAll('.vf-image-upload-form').forEach(image_form => new ImageCropper(image_form));
  }
  constructor(container) {
    this.imageEl = container.querySelector('.vf-image-cropper img');
    this.widthInput = container.querySelector('input[name$="[target_width]"]');
    this.heightInput = container.querySelector('input[name$="[target_height]"]');
    this.fileInput = container.querySelector('input[type="file"]');
    this.urlAttrInput = container.querySelector('.vf-image-url');
    this.cropInfoInput = container.querySelector('.image_crop_info');
    this.cropErrorContainer = container.querySelector('.vf-crop-errors span');

    this.imageEl.addEventListener('crop', event => this._updateCropInfo(event.detail));
    this.cropper = this._createCropper(this.imageEl);

    [this.widthInput, this.heightInput].forEach(input => input.addEventListener('change', () => this._updateAspectRatio()));

    // enable pasting images (eg. from snipping) directly
    window.addEventListener('paste', event => {
      if (event.clipboardData.files.length === 1) {
        event.preventDefault();
        this.fileInput.files = event.clipboardData.files;
        this.fileInput.dispatchEvent(new Event('change', { bubbles: true }));
        console.log('File successfully pasted');
      }
    });

    // and add the drop zone for drag-and-dropping images
    const dropZone = document.querySelector('.vf-image-upload-form');
    ['dragover', 'dragenter'].forEach(d => dropZone.addEventListener(d, event => {
      event.stopPropagation();
      event.preventDefault();
    }));
    dropZone.addEventListener('drop', event => {
      event.preventDefault();
      if (event.dataTransfer && event.dataTransfer.files.length === 1) {
        this.fileInput.files = event.dataTransfer.files;
        this.fileInput.dispatchEvent(new Event('change', { bubbles: true }));
        console.log('File successfully dropped');
      }
    });


    // if the image file input changes, load the image and recreate the cropper
    this.fileInput.addEventListener('change', event => {
      const selectedImage = event.currentTarget.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', event => {
        this.imageEl.src = event.target.result;
        if (this.cropper != null) {
          this.cropper.destroy();
        }
        this.cropper = this._createCropper(this.imageEl);
      });
      reader.readAsDataURL(selectedImage);

      const path = event.currentTarget.value;
      this.urlAttrInput.value = path.substring(path.lastIndexOf('\\') + 1);
    });
  }
  _createCropper (img) {
    // dont crop the default image
    if (!img.src.includes('/default.png')) {
      return new Cropper(img, {
        aspectRatio: this.widthInput.value / this.heightInput.value,
        viewMode: 1, // restrict the crop box not to exceed the size of the canvas
        autoCropArea: 1, // start with the largest possible area (100%)
        zoomOnWheel: false,
        zoomable: false,
        restore: false
      });
    }
    return null;
  };
  _updateAspectRatio() {
    // if the values are not positive numbers, remove the aspect ratio lock
    const newWidth = parseInt(this.widthInput.value);
    const newHeight = parseInt(this.heightInput.value);
    if (isFinite(newWidth) && newWidth > 0 && isFinite(newHeight) && newHeight > 0) {
      this.cropper.setAspectRatio(newWidth / newHeight);
    }
    else {
      this.cropper.setAspectRatio(null);
    }
  };

  _updateCropInfo (info) {
    this.cropInfoInput.value = JSON.stringify(info);

    // display warning if the crop values are below the desired width/height
    const targetWidth = parseInt(this.widthInput.value);
    const targetHeight = parseInt(this.heightInput.value);

    this.cropErrorContainer.textContent = ""

    if (targetWidth > info.width && isFinite(targetWidth) && targetWidth > 0){
      console.warn('The cropped width is smaller then the target width!')
      this.cropErrorContainer.textContent = "A kép a megadott méretnél kisebbre lesz vágva!"
    }
    if (targetHeight > info.height && isFinite(targetHeight) && targetHeight > 0){
      console.warn('The cropped height is smaller then the target height!')
      this.cropErrorContainer.textContent = "A kép a megadott méretnél kisebbre lesz vágva!"
    }
  }
}