/* global require, __dirname, __webpack_public_path__ */

window.CKEDITOR_BASEPATH = __webpack_public_path__ + 'node_modules/ckeditor/';

// Load your custom config.js file for CKEditor.
require(`!file-loader?context=${__dirname}&outputPath=node_modules/ckeditor/&name=[path][name].[ext]!./config.js`);

// Load your custom contents.css file in case you use iframe editor.
require(`!file-loader?context=${__dirname}&outputPath=node_modules/ckeditor/&name=[path][name].[ext]!./contents.css`);

// Load your custom styles.js file for CKEditor.
require(`!file-loader?context=${__dirname}&outputPath=node_modules/ckeditor/&name=[path][name].[ext]!./styles.js`);


require.context(
  '!file-loader?name=[path][name].[ext]!ckeditor/plugins/',
  true,
  /^\.\/((a11yhelp|about|basicstyles|blockquote|button|clipboard|contextmenu|dialog|dialogui|elementspath|enterkey|entities|fakeobjects|filebrowser|filetools|floatingspace|floatpanel|format|horizontalrule|htmlwriter|image|indent|indentlist|lineutils|link|list|listblock|magicline|maximize|menu|menubutton|notification|notificationaggregator|panel|pastefromword|pastetext|popup|removeformat|resize|richcombo|scayt|showborders|sourcearea|specialchar|stylescombo|tab|table|tableselection|tabletools|toolbar|undo|uploadimage|uploadwidget|widget|widgetselection|wsc|wysiwygarea)(\/(?!lang\/)[^/]+)*)?[^/]*$/
);

require.context(
  '!file-loader?name=[path][name].[ext]!ckeditor/plugins/',
  true,
  /^\.\/(a11yhelp|about|basicstyles|blockquote|button|clipboard|contextmenu|dialog|dialogui|elementspath|enterkey|entities|fakeobjects|filebrowser|filetools|floatingspace|floatpanel|format|horizontalrule|htmlwriter|image|indent|indentlist|lineutils|link|list|listblock|magicline|maximize|menu|menubutton|notification|notificationaggregator|panel|pastefromword|pastetext|popup|removeformat|resize|richcombo|scayt|showborders|sourcearea|specialchar|stylescombo|tab|table|tableselection|tabletools|toolbar|undo|uploadimage|uploadwidget|widget|widgetselection|wsc|wysiwygarea)\/(.*\/)*lang\/(en|hu)\.js$/
);

// Load CKEditor lang files.
require.context(
  '!file-loader?name=[path][name].[ext]!ckeditor/lang',
  true,
  /(en|hu)\.js/
);

// Load skin.
require.context(
  '!file-loader?name=[path][name].[ext]!ckeditor/skins/moono-lisa',
  true,
  /.*/
);
