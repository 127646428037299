import './loader.js';
import 'ckeditor/ckeditor';

window.CKEDITOR.config.customConfig = 'config.js';

// destroy and remake CKEDITOR instances on turbolinks load
document.addEventListener('turbolinks:load', function() {
  if (typeof window.CKEDITOR !== 'undefined') {
    for (let instanceName in window.CKEDITOR.instances) {
      window.CKEDITOR.instances[instanceName].destroy();
    }
  }

  document.querySelectorAll('.ckeditor').forEach(editor => {
    window.CKEDITOR.replace(editor.id);
  });
});
