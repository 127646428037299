/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../stylesheets/admin';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from 'rails-ujs';
import ImageCropper from '../image_cropper';
import Validator from '../validator';

import { setAdminLocale, hideFlash, pascalToSnake } from '../utils';
import 'ckeditor_loader';

Rails.start();

window.query = document.querySelector.bind(document);
window.queryAll = document.querySelectorAll.bind(document);
window.globalListener = document.addEventListener.bind(document);

ImageCropper.init();

const body = document.querySelector('body');
['DOMContentLoaded', 'turbolinks:load'].forEach(e => {
  document.addEventListener(e, () => {
    hideFlash(3000);
    setAdminLocale();

    queryAll('.vf-remote-form').forEach(form => {
      form._validator = new Validator();
      form._validator.submitWithRemoteValidation(form.id);
    });
    const temp = document.querySelector('body').dataset.controller;
    if (temp) {
      const controller = pascalToSnake(temp);
      const action = body.dataset.action;
      const variant = body.dataset.variant;
      const namespaces = body.dataset.namespace ? body
        .dataset
        .namespace
        .split('::')
        .map(pascalToSnake) : []

      console.assert(namespaces[0] === 'admin', 'The first namespace is not admin');

      namespaces.shift();

      const fileName = namespaces.length === 0 ? `${controller}.js` : `${namespaces.join('_')}_${controller}.js`;

      import(`../views/admin/${fileName}`).then(module => {
        const viewInitializer = module[`init${action}`];
        if (typeof viewInitializer === 'function') {
          try {
            viewInitializer({isMobile: variant === 'mobile'})
          } catch (error) {
            console.error(`Exception in view module: ${error}`);
          }
        }
      }).catch(error => console.log(`View module not found: ${error} `));
    }
  });
});
